.Suggestions {


    &__Item {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 5px;
        padding: var(--space-xs);

        &:hover {
            background-color: var(--color-control-bg-clear-hover);
            cursor: pointer;
        }

        .name-with-opf {
            font-weight: bold;
        }

        .suggestion-footer {
            display: flex;
            justify-content: space-between;
        }
    }
}