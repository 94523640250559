.HeaderBreadcrumbs {
    width: 500px;

    .BreadcrumbsItem {
        &:not(:last-child) {
            cursor: pointer;

            .Text {
                &:hover {
                    color: var(--color-typo-link-hover) !important;
                }
            }

        }

        span {
            color: var(--color-control-typo-primary);
        }
    }
}