.ColorValue{
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
  border-radius: 50%;
}