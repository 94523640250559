.Section {
	background: var(--color-bg-default);
	height: max-content;
	border-radius: var(--control-radius);


	&__content {
		padding: var(--space-section);
	}

	header {
		background: var(--color-bg-system);
		color: var(---text);
		font-size: 1rem;
		font-weight: 500;
		padding: 0 var(--space-l);
		height: var(--space-4xl);
		min-height: var(--space-4xl);
		display: flex;
		align-items: center;
		border-radius: var(--control-radius) var(--control-radius) 0 0;
	}

	// cтили таблицы
	.sp-table {
		border: 1px solid var(--color-bg-system);
	}

}