.settings-tool-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 15px;
    align-items: flex-start;



    .label-field {
        margin-top: 20px;
        width: 100%;
    }

    .radio {
        display: flex;
        flex-direction: column;

        label {
            display: flex;
            align-items: center;
            gap: 5px;
            input {
                height: 1.5em;
            }
        }
    }

    .leftSidebar,
    .floatingFilter {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}