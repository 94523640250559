.variant-prices {

	.ranges {
		display: grid;
		grid-template-columns: auto minmax(80px, 1fr);
		row-gap: 10px;
		column-gap: 20px;
		align-items: center;
		width: 100%;
	}
}
