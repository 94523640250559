.staff-single-content {
	background: var(--color-bg-default);
	padding: var(--space-m) var(--space-l);
	border-radius: var(--border-radius);
	
	.RouteTabs {
		flex-direction: row !important;
		.staff-form {
			.Grid {
				max-width: 536px;
			}
			.staff-form-container {
				display: flex;
				flex-direction: column;
				gap: var(--space-m);
			}
			hr {
				margin-top: var(--space-m);
				margin-bottom: var(--space-m);
			}
			
			.toggle-copy-address {
				margin-top: var(--space-m);
			}

		}
		.staff-form-personal {
			.Grid {
				max-width: 700px;
			}
			.staff-form-all-documents-btn {
				margin-top: var(--space-m);
			}
		}

		.Tabs-List {
			width: 300px;
		}
	}
}

.toolbar-staff {
	margin-bottom: var(--space-l);
}