:root {
	// везде используйте эту переменную для определения ширины карточки канбана
	--list-width: 280px;
}

#dnd-context {
	position: relative;

	.kb-draggable {
		// position: absolute;
		// pointer-events: none;
		// user-select: none;
		// left: -1000px;
		// top: -1000px;
		// width: var(--list-width, 240px);
		// z-index: 2000;
		color: unset;
	}
}


.kb-container {
	.hdr.hidden {
		border: none;
	}

	.kb-list-info {
		display: none !important;
	}

	.kb-list {
		margin-right: 20px;



		.kb-items {
			.kb-item {

				position: relative;
				z-index: 0;
				color: unset;
			}
		}
	}
}

/*
	Этот класс не должен быть исключительно внутри .kb-container > .kb-list > .kb-items > .kb-item,
	потому что при перетаскивании элемент канбана выпадает из .kb-container и попадает в body > #root > #dnd-context
*/

.kb-item-content {
	border: none;
	border-radius: var(--space-2xs);
	border-left: 6px solid;
	position: relative;
	box-shadow: none;
}

.kb-new-item {
	background: var(--color-bg-default);
	border-color: var(--border-color);
	border-radius: var(--control-radius);
	max-width: calc(var(--list-width));
	border-left-width: 6px;


	textarea {
		background: var(--color-control-bg-default) !important;
		border: var(--control-border-width) solid var(--color-control-bg-border-default) !important;
		color: var(--color-control-typo-default) !important;
		margin-bottom: var(--space-);

		&:hover {
			border-color: var(--color-control-bg-border-default-hover) !important;
			background-color: unset;
		}

		&:active,
		&:focus {
			border-color: var(--input-active-border-color) !important;
		}
	}

	.actions {
		button {
			color: var(--text-light);

			&:first-child {
				margin-right: 10px;
			}
		}
	}
}