.log {
	margin-bottom: var(--space-m);
	display: flex;
	align-items: flex-start;
	gap: var(--space-s);
	border-bottom: 1px solid var(--color-bg-system);

	.roles {
		display: flex;
		flex-direction: row;
		gap: var(--space-xs);
	}

	.what {
		flex-grow: 1;
		display: flex;
		gap: 10px;
		flex-direction: column;
		padding-bottom: 20px;

		.top {
			display: flex;
			gap: var(--space-s)
		}

		.actions {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			gap: var(--space-xs);
		}

		.render-status,
		.TagBase {
			margin-left: 5px;
			cursor: default;

			&:hover {
				--tag-base-border-color: var(--color-control-bg-border-default)
			}
		}

		.action {
			padding-right: 16px;

			a {
				position: relative;

				&>div {
					cursor: default;
					position: absolute;
					min-width: 100px;
					max-width: 300px;
					line-height: 1.3em;
					min-height: 32px;
					max-height: 200px;
					overflow: auto;
					bottom: 18px;
					left: 0;
					background: #fffcf0;
					padding: 10px;
					border: var(--border-width) solid rgba(#000, 0.2);
					box-shadow: 1px 3px 3px rgba(#000, 0.1);
					color: #000;

					a {
						color: #000;
						pointer-events: none;
					}
				}
			}
		}
	}
}

@media (max-width: 854px) {
	.log {
		.what {
			flex-grow: 1;
			overflow: hidden;

			.top {
				flex-wrap: wrap;

				.actions {
					width: 100%;

					.action {
						line-height: 1.2em;
					}
				}
			}

			.comment {
				.panel-buttons {
					overflow: auto;
					width: 100%;
				}
			}
		}
	}
}