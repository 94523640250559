.KanbanCard__Bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .counts {
        border: var(--control-border-width) solid var(--color-control-bg-border-default);
        border-radius: var(--control-radius);
        padding: 0 6px;
        display: flex;
        flex-direction: row;
        gap: var(--space-s);
        flex-wrap: nowrap;
        margin-left: auto;
        min-width: max-content;
        max-width: 30%;



        .attachmentCount,
        .commentCount {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            gap: var(--space-2xs)
        }
    }
}