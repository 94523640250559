.sp-btn {
	--default-border: var(--primary);
	--primary-bg: var(--color-control-bg-primary);
	--disabled-bg: var(--input-disabled-bg);
	--default-font-size: 1rem;
	height: var(--control-height-m);
	font-size: var(--control-text-size-m);
	font-weight: 500;
	position: relative !important;
	padding: 0 16px;

	&:not(.sp-btn-link):hover {
		box-shadow: unset;
	}

	svg {
		height: 18px;
		margin-top: 0;

		*[fill] {
			transition: all 150ms;
		}
	}

	span {
		margin-left: unset;
		transition: all 150ms;

		&+* {
			margin-left: 0;
		}
	}

	&.sp-btn-primary {

		--button-primary-disabled-text: var(--text-lighter);

		&:disabled {
			background-color: var(--color-control-bg-disable);
			color: var(--button-primary-disabled-text);
		}

		&:active {
			background-color: var(--color-control-bg-primary-hover);
		}

		&:hover {
			background-color: var(--color-control-bg-primary-hover);
			;
		}
	}

	&.sp-btn-sm {
		font-size: 13px;
		max-height: 28px;
		text-transform: unset;
		font-weight: unset;
	}

	&.sp-btn-default {
		--default-border: var(--color-control-bg-border-secondary);
		--default-bg: var(--color-control-bg-secondary);
		--default-text: var(--pager-text);
		margin-left: 0;
		border-width: var(--border-width);
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		gap: 10px;

		span {
			color: var(--color-control-typo-secondary);
		}

		svg {
			width: calc(20 * 1em / 14);
			height: calc(20 * 1em / 14);

			*[fill] {
				transition: all var(--transition-time);
				fill: var(--color-control-typo-secondary);
			}

			*[stroke] {
				transition: all var(--transition-time);
				stroke: var(--color-control-typo-secondary);
			}

			rect[fill] {
				transition: all var(--transition-time);
				fill: var(--color-control-typo-secondary);
			}
		}

		&.active {
			--default-bg: var(--secondary-light);
			--default-border: transparent;
		}



		&:hover {
			background: var(--color-control-bg-secondary) !important;
			color: var(--color-control-typo-secondary-hover) !important;
			border-color: var(--color-control-bg-border-secondary-hover) !important;
		}

		&:active {
			background-color: unset !important;
		}

		&:focus {
			animation: Animation--MixFocus 0.5sease-in;
			box-shadow: 0 0 0 3px var(--color-control-bg-focus);
			outline: none;
		}

		&:disabled {
			border-color: transparent !important;

			span {
				color: var(--text-lighterer);
			}

			svg {
				*[fill] {
					fill: var(--disabled-text);
				}

				*[stroke] {
					stroke: var(--disabled-text);
				}
			}
		}
	}
}