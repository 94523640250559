.range-date-picker {
	display: flex;
	flex-direction: row;
	gap: 10px;


	.Select {
		min-width: 140px;
		width: 140px;
	}

	.TextField {
		min-width: 160px;
		width: 160px;
	}
}