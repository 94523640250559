.UserSelect {
    .Select-ControlValue {
        width: 100%;
    }

    .Select-SelectContainer {
        &:not(.Select-SelectContainer_multiple) {
            .renderUser {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;

                .UserAvatar {
                    height: 100%;
                }
            }
        }
    }


    .User {
        cursor: pointer !important;

        &:hover {
            background-color: initial;
        }
    }

    .UserAvatar {
        display: inline-flex;
    }

}