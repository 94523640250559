.staff-documents-popup-list {
	width: 800px;
}
.staff-documents-popup-create {
	width: 600px;
}
.staff-documents-popup {
	right: 0;
	top: 0;
	height: 100vh;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	gap: var(--space-m);
	border-radius: unset;

	.staff-documents-content {
		display: flex;
		flex-direction: column;
		gap: var(--space-m);
		padding: var(--space-2xl);
	}

	.staff-documents-footer {
		margin-top: auto;
		padding: var(--space-2xl);
		display: flex;
		gap: var(--space-m);
		border-top: var(--control-border-width) solid var(--color-control-bg-border-default);
		justify-content: flex-end;
	}
}
