.renderUser {
    .User {
        max-width: unset;

        &:hover {
            background-color: transparent !important;
        }

        .Text {
            max-width: unset;
        }
    }
}