.DelayedLoader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 3s forwards;

}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}