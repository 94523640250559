.product-variant {
	border-top: 1px solid var(--border-color);
	padding: var(--space-m) 0;
	margin-bottom: var(--space-m);
	display: flex;
	align-items: flex-start;
	gap: 24px;
	width: 100%;

	.right-nomenclature-card{
		flex-grow: 1;

		& > div{
			padding: var(--space-s) 0;
			display: flex;
			flex-direction: column;
			gap: var(--space-m);

			&:first-child{
				padding-top: 0;
			}
		}

		.actions{
			flex-direction: row;
			justify-content: flex-end;
		}
	}

}