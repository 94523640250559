.KanbanCard__PositionTotal {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sum,
    .positionCount {
        display: flex;
        flex-direction: row;
    }
}