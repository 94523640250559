.AgGridTemplatesPanel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    height: 100%;

    &__List {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--space-s);
        padding: var(--space-l) var(--space-s) var(--space-l) var(--space-l);

        .title {
            margin-bottom: var(--space-s);
        }

        &:not(:last-child) {
            border-bottom: var(--control-border-width) solid var(--color-control-bg-border-default)
        }
    }

    .Loader {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        gap: var(--space-xs);
        height: var(--space-2xl);


        .name {
            cursor: pointer;
        }

        .FieldGroup {
            width: 100%;

            input {
                width: 100% !important;
                height: unset;
                padding: unset;
            }
        }


    }

    .auto-animate {
        width: 100%;
    }
}