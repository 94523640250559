.KanbanCard__Top {
    color: var(--color-typo-secondary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    .left {
        display: flex;
        align-items: center;
        gap: var(--space-2xs);

        .SvgCheck {
            margin-bottom: 3px;
        }
    }

    .id {
        gap: unset;
    }

    .date {
        max-width: 60%;
    }
}