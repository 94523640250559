.Dictionary{
  height: 100%;
  display: flex;
  flex-direction: column;

  &-toolbar{
    .toolbar-search{
      flex-grow: 1;
    }
  }
}