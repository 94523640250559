.Dictionary-edit {
  width: 600px;
  padding: var(--space-2xl);
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  max-height: 100%;

  &-content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    padding: 5px;
    margin: -5px;
    width: calc(100% + 10px);


    &>div:first-child {
      flex-grow: 1;
      margin: -5px;
      padding: 5px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: var(--space-l);
    }
  }
}