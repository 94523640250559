.ColorPicker {
  &-label {
    margin-bottom: var(--space-xs);
  }

  &-capture {
    margin-top: var(--space-xs);
  }

  &-btn {
    display: block;
    width: 80px;
    border: 1px solid var(--color-bg-border);
  }

  &-input {
    display: flex;
    align-items: center;
    gap: var(--space-xs);
  }

  &-popover_disabledInput {
    input {
      display: none;
    }
  }
}

.ColorPicker-popover {
  .block-picker {
    background: var(--color-bg-default) !important;
    border-radius: var(--control-radius) !important;
    border: var(--control-border-width) solid var(--color-control-bg-border-default) !important;

    input {
      background: var(--color-control-bg-default) !important;
      border: var(--control-border-width) solid var(--color-control-bg-border-default) !important;
      border-radius: var(--control-radius) !important;
      color: var(--color-control-typo-default) !important;
      min-height: var(--control-height-m) !important;
      font-family: var(--font-primary) !important;
      font-weight: var(--font-weight-text-regular) !important;
      box-shadow: unset !important;

      &:hover {
        border-color: var(--color-control-bg-border-default-hover) !important
      }

      &:focus {
        border-color: var(--color-control-bg-border-focus) !important
      }
    }
  }
}